function getToken(){
    const token = localStorage.getItem('token_junta');
    
    if(token === null) return null;

    let decrypted = atob(token);
    
    return decrypted;
}

const config = {
    headers: { 
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json'
    }
}

export default config;